import TrackService from "@/services/track-service";
import { PaginatedResult } from "@/types/pagination";
import { Statistics, Track, TrackPreview } from "@/types/track";
import { handleError } from "@/utils/error";
import { defineStore } from "pinia";

const service = new TrackService("tracks");

export const useTrackStore = defineStore("track", {
  state: () => ({
    tracks: [] as Track[],
    total: 0,
    preview: {} as TrackPreview,
    trackById: {} as { [id: string]: Track },

    statisticsLoadingById: {} as Record<string, boolean>,
    statisticsById: {} as Record<string, Statistics>,

    previewLoadingById: {} as Record<string, boolean>,
    previewById: {} as Record<string, TrackPreview>,
  }),
  actions: {
    clearAll() {
      this.tracks = [];
      this.total = 0;
      this.preview = {
        title: "",
        previewUrl: "",
      };
    },

    async getById(id: string): Promise<Track> {
      try {
        return await service.getById(id);
      } catch (error) {
        throw handleError(error);
      }
    },

    async getTracks(uri: string): Promise<PaginatedResult<Track> | void> {
      try {
        const res: PaginatedResult<Track> | void = await service.list(uri);
        if (res && res.data) {
          this.tracks = res.data;
          this.total = res.total;
        }
      } catch (error) {
        throw handleError(error);
      }
    },

    async addTrack(
      title: string,
      link: string,
      description: string,
      isPrivate: boolean,
      referrer?: string,
      isReleased?: boolean,
      addToCatalog?: boolean,
      fileExtension?: string,
    ): Promise<void | Track> {
      try {
        const track = await service.addTrack(
          title,
          link,
          description,
          isPrivate,
          referrer,
          isReleased,
          addToCatalog,
          fileExtension,
        );
        this.tracks = [track, ...this.tracks];
        this.total++;
        return track;
      } catch (error) {
        throw handleError(error);
      }
    },

    async editTrack(
      id: string,
      title: string,
      description: string,
    ): Promise<void | Track> {
      try {
        const track = await service.editTrack(id, title, description);
        if (track) {
          const index = this.tracks.findIndex((item) => item._id === track._id);
          this.tracks.splice(index, 1, track);
          return track;
        }
      } catch (error) {
        throw handleError(error);
      }
    },

    async removeTrack(id: string): Promise<void> {
      try {
        await service.removeTrack(id);
        const index = this.tracks.findIndex((item) => item._id === id);
        this.tracks.splice(index, 1);
        this.total--;
      } catch (error) {
        throw handleError(error);
      }
    },

    async getPreview(link: string): Promise<TrackPreview> {
      try {
        this.preview = await service.getPreview(link);

        return this.preview as TrackPreview;
      } catch (error) {
        throw handleError(error);
      }
    },

    async getStatistics(id?: string) {
      if (!id) return;

      try {
        if (this.statisticsById[id]) {
          return this.statisticsById[id];
        }

        this.statisticsLoadingById[id] = true;
        this.statisticsById[id] = await service.getStatistics(id);
      } catch (error) {
        console.error(error);
      } finally {
        this.statisticsLoadingById[id] = false;
      }
    },

    async getPreviewByTrackId(id?: string): Promise<TrackPreview | undefined> {
      if (!id) {
        return;
      }

      try {
        if (this.previewById[id]) {
          return this.previewById[id];
        }

        this.previewLoadingById[id] = true;
        this.previewById[id] = await service.getPreviewById(id);

        return this.previewById[id];
      } catch (error) {
        console.error(error);
      } finally {
        this.previewLoadingById[id] = false;
      }
    },
  },
});
