import { handleError } from "@/utils/error";
import { Statistics, Track, TrackPreview } from "@/types/track";

import BaseService from "./base-service";
import { PaginatedResult } from "@/types/pagination";
import { getGAVariables } from "@/utils/helpers";

class TrackService extends BaseService {
  constructor(_rootEndpoint: string) {
    super(_rootEndpoint);
  }

  public async getById(id: string): Promise<Track> {
    try {
      return await this.get(`${this.getServiceContext}/${id}`);
    } catch (error) {
      throw handleError(error);
    }
  }

  public async list(uri: string): Promise<PaginatedResult<Track> | void> {
    try {
      return await this.get(`${this.getServiceContext}?${uri}`);
    } catch (error) {
      throw handleError(error);
    }
  }

  public async addTrack(
    title: string,
    link: string,
    description: string,
    isPrivate: boolean,
    referrer?: string,
    isReleased?: boolean,
    addToCatalog?: boolean,
    fileExtension?: string,
  ): Promise<Track> {
    try {
      const analytics = await getGAVariables();
      return await this.post(`${this.getServiceContext}`, {
        title,
        link,
        description,
        isPrivate,
        referrer,
        isReleased,
        addToCatalog,
        fileExtension,
        analytics,
      });
    } catch (error) {
      throw handleError(error);
    }
  }

  public async editTrack(
    id: string,
    title: string,
    description: string,
  ): Promise<Track | void> {
    try {
      return await this.update(`${this.getServiceContext}/${id}`, {
        title,
        description,
      });
    } catch (error) {
      throw handleError(error);
    }
  }

  public async removeTrack(id: string): Promise<Track | void> {
    try {
      return await this.delete(`${this.getServiceContext}/${id}`, {});
    } catch (error) {
      throw handleError(error);
    }
  }

  public async getPreview(link: string): Promise<TrackPreview> {
    return await this.get(
      `${this.getServiceContext}/preview?link=${escape(link)}`,
    );
  }

  public async getPreviewById(id: string): Promise<TrackPreview> {
    return await this.get(`${this.getServiceContext}/${id}/preview`);
  }

  public async getStatistics(id: string): Promise<Statistics> {
    return await this.get(`${this.getServiceContext}/${id}/stats`);
  }
}

export default TrackService;
